/* Typography ************************************ */

.font-bold {
  font-weight: bold;
}

/* Padding and MJargin ************************************ */

.padding-top-1 {
  padding-top: 1.5rem;
}

.margin-top-1 {
  margin-top: 1.5rem;
}

