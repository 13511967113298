.pointer-link {
  text-decoration: none;
  color: blue;
}

.h1 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  margin: 0;
}

.date-with-boolean {
  display: flex;
  align-items: center;
}

.date-with-boolean-check {
  margin: 0 0 0 10px;
}

.referrer {
  cursor: pointer;
  color: #4f3cc9;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  border-top: solid 0.5px #cbc6c6;
  margin: 25px 0 10px 0;
}

.wrapper:first-child {
  border-top: none;
}

.wrapper>div {
  width: 20%;
  padding: 0 20px;
}

.wrapper>div:first-child {
  padding: 0;
}

.wrapper>div>h1 {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em
}

.wrapper>div>span {
  word-break: break-all;
}

pre {
  height: 200px;
  overflow-y: auto;
  overflow-x: auto;
  background-color: rgb(239, 239, 239);
  border: solid rgb(222, 219, 219) 1px;
  border-radius: 5px;
  box-shadow: 0 2px 5px silver;
  margin-right: 30px;
  padding: 10px;
  min-width: 300px;
  max-width: 500px;
}


.wrapper-pre {
  justify-content: flex-start;
}

pre.bulk {
  height: 600px;
  padding: 10px;
  max-width: 80%;
}

.wrapper-pre>h1 {
  color: rgba(0, 0, 0, 0.54);
  padding: 0 10px 0 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em
}

.red-btn {
  color: #f44336;
}

.input-txt {
  padding: 10px;
  font-size: 20px;
  outline: none;
  resize: none;
  width: 90%;
  height: 200px;
}

.number-input {
  width: 90%;
  padding: 10px;
  font-size: 20px;
  outline: none;
}

.m-3 {
  margin: 20px;
}

.MuiPaper-rounded {
  padding: 10px;
}

.verify-email-text {
  background-color: #4f3cc9;
  color: white;
  border: #4f3cc9 solid 1px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.checkmark {
  padding: 0 2px;
}

.admin-title {
  background: purple;
  padding: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.add-blockchain-id-button {
  min-width: 170px !important;
}

.kyc-date-picker-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 40%;
  float: right;
  padding: 20px 0;
}

.kyc-nav-container{
  display: flex;
  justify-content: space-between;
}

.text-red {
  color: #f44336 !important;
}

.hint {
  margin-left: 40px;
  color: #f44336;
  font-family: monospace;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-9:disabled {
  cursor: default;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.filter-date-holder{
  border: solid 1px #e0e0e3;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  font-family: cursive;
  margin-top: 20px;
}