fieldset.three-ways-switch {
    border: none;
    padding: 0;
    margin: 0;
}
legend.three-ways-switch {
    padding: 0;
    margin: 0;
}
label.three-ways-switch {
    cursor: pointer;
    display: block;
    float: left;
    padding: .2em .4em;
    background-color: #ccc;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: #666;
    background-image: -moz-linear-gradient(rgba(255,255,255,.2), rgba(0,0,0,.2));
    background-image: -webkit-linear-gradient(rgba(255,255,255,.2), rgba(0,0,0,.2));
    background-image: linear-gradient(rgba(255,255,255,.2), rgba(0,0,0,.2));
    box-shadow: 1px 1px 1px rgba(255,255,255,.2) inset, -1px -1px 1px rgba(0,0,0,.2) inset;
}
label.three-ways-switch:first-child {
    border-left-width: 1px;
    border-top-left-radius: .2em;
    border-bottom-left-radius: .2em;
}
label.three-ways-switch:last-child {
    border-top-right-radius: .2em;
    border-bottom-right-radius: .2em;
}
label.selected.three-ways-switch {
    background-color: #0066CC;
    color: white;
    border-color: darkblue;
    background-image: -moz-linear-gradient(rgba(0,0,0,.2), rgba(255,255,255,.2));
    background-image: -webkit-linear-gradient(rgba(0,0,0,.2), rgba(255,255,255,.2));
    background-image: linear-gradient(rgba(0,0,0,.2), rgba(255,255,255,.2));
    box-shadow: 1px 1px 1px rgba(0,0,0,.2) inset;
}
label.warning.selected.three-ways-switch {
    background-color: red;
    border-color: darkred;
}
label.off.selected.three-ways-switch {
  background-color: rgb(71, 71, 71);
  border-color: rgb(33, 33, 33);
}
input[type="radio"].three-ways-switch {
   position: absolute;
   top: -9999px;
   left: -9999px;
}